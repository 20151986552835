import React, { useEffect } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import tw from 'tailwind.macro'

const Inner = styled.div`
  line-height: 0;
  ${tw`
  max-w-2xl
  mx-auto
  my-0
  `}
`

function JobsListing({ source, title }) {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = source
    script.id = 'job_listing'
    document.body.appendChild(script)
  })

  return (
    <div>
      <Inner>
        {title && (
          <h2
            className={classnames(
              'text-35px',
              'font-sans',
              'leading-none',
              'font-extrabold',
              'mt-6',
              'lg:mt-8',
              'mb-3',
              'lg:mb-4'
            )}
          >
            {title}
          </h2>
        )}
        <div
          id="gnewtonjs"
          className={classnames({
            'pt-6': !title,
          })}
        />
      </Inner>
    </div>
  )
}

export default JobsListing
