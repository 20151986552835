import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import classNames from 'classnames'

const Wrapper = styled.div`
  p {
    ${tw`mb-2`}
  }

  a {
    ${(props) => {
      if (props.textWhite) {
        return tw`
          text-white
          underline
        `
      } else {
        return tw`
          text-green-3
          no-underline
          hover:underline
        `
      }
    }}
`

const Paragraph = ({
  children,
  largeText,
  centered,
  as = 'p',
  textWhite = false,
  ...rest
}) => {
  const paragraphBaseClasses = `
    font-sans
    font-light
    text-base
    leading-normal
  `

  const paragraphClasses = classNames(paragraphBaseClasses, {
    'text-lg font-light text-dark-grey-2': !largeText,
    'text-3xl font-bold text-charcoal': largeText,
    'text-center': centered,
  })

  return (
    <Wrapper
      as={as}
      className={paragraphClasses}
      textWhite={textWhite}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

export default Paragraph
