import React from 'react'
import VideoWithText from './components/VideoWithText'
import QuotesSlider from './components/QuotesSlider'
import TwoColumnWithItems from './components/TwoColumnWithItems'
import TabNav from '../../../components/General/TabNav/TabNav'
import ImageWithTwoColumns from './components/ImageWithTwoColumns'
import FiftyFifty from './components/FiftyFifty'
import BlockquoteImage from './components/BlockquoteImage'
import ColumnWithImage from './components/ColumnWithImage'
import VideosRow from './components/VideosRow'
import VideoWithTitle from './components/VideoWithTitle'
import JobsListing from './components/JobsListing'
import BottomBanner from './components/BottomBanner'
import SplashMantel from '../../../components/General/SplashMantel'
import TwoColumnText from './components/TwoColumnText'

function RenderComponent({ component, data }) {
  if (!data) return null
  switch (component.slice_type) {
    case 'video_block': {
      return <VideoWithText items={component.items || []} />
    }
    case 'page_heading': {
      return (
        <SplashMantel
          heading={data.mantel_title.text}
          subheading={data.mantel_description.text}
          mantelBackground={data.mantel_image.url}
          cta={{
            url: data.mantel_link_url.url,
            text: data.mantel_link_text.text,
          }}
        />
      )
    }
    case 'sub_navigation': {
      return (
        <div className="max-w-2xl my-0 mx-auto">
          <TabNav
            items={data.navigation_items.map((item) => ({
              url: item.navigation_item_link_url.text,
              text: item.navigation_item_link_text.text,
              active:
                data.currentPath === item.navigation_item_link_url.text ||
                `${item.navigation_item_link_url.text}`.endsWith(
                  data.currentPath
                ),
            }))}
            useSmallMargins
          />
        </div>
      )
    }
    case 'two_column_with_items': {
      return (
        <TwoColumnWithItems
          title={component.primary.title.text}
          columnLeftTitle={component.primary.column_left_title.text}
          columnLeftDescription={component.primary.column_left_text.text}
          columnRightTitle={component.primary.column_right_title.text}
          columnRightDescription={component.primary.column_right_text.html}
          items={component.items.map((item) => ({
            icon: item.icon.url,
            text: item.text.text,
          }))}
          itemsTitle={component.primary.items_list_title.text}
          cta={{
            url: component.primary.button_link.text,
            text: component.primary.button_text.text,
          }}
        />
      )
    }
    case 'image_with_two_columns_': {
      return (
        <ImageWithTwoColumns
          title={component.primary.title.text}
          description={component.primary.description.html}
          cta={{
            text: component.primary.button_text.text,
            url: component.primary.button_link.text,
          }}
          image={component.primary.image.url}
        />
      )
    }
    case 'fifty_fifty': {
      return (
        <FiftyFifty
          title={component.primary.title.text}
          description={component.primary.description.text}
          cta={{
            text: component.primary.button_text.text,
            url:
              component.primary &&
              component.primary.button_link &&
              component.primary.button_link
                ? component.primary.button_link.text
                : '',
          }}
          media={component.primary.media.url}
          poster={
            component.primary.video_poster
              ? component.primary.video_poster.url
              : null
          }
          useBackground
          isVideo={component.primary.treat_media_as_video}
        />
      )
    }
    case 'block_quote_image': {
      return (
        <BlockquoteImage
          className="bg-light-grey-2"
          image={{
            src: component.primary.author_image
              ? component.primary.author_image.url
              : component.primary.image
              ? component.primary.image.url
              : null,
          }}
          quote={component.primary.quote.text}
          author={component.primary.author_name.text}
          position={component.primary.author_position.text}
          skew={!!component.skew}
        />
      )
    }
    case 'script_embed': {
      return (
        <JobsListing
          title={
            component.primary.embed_title
              ? component.primary.embed_title.text
              : null
          }
          source={component.primary.script_embed}
        />
      )
    }
    case 'bottom_banner': {
      return (
        <BottomBanner
          cta={{
            url: data.bottom_banner_link_url.url,
            text: data.bottom_banner_link_text.text,
          }}
          text={data.bottom_banner_text.text}
          background="https://images.prismic.io/algorandcom/23e9745d-5266-4bbe-84e3-c3e4e195947c_bg_quote.jpg?auto=compress,format"
        />
      )
    }
    case 'quote_slider': {
      return (
        <QuotesSlider
          items={component.items.map((item) => ({
            image: item.image.url,
            quote: item.quote.text,
            author: item.author_name.text,
            position: item.author_position.text,
          }))}
        />
      )
    }
    case 'column_with_image': {
      return (
        <ColumnWithImage
          title={component.primary.title.text}
          subtitleUp={component.primary.subtitle_up.text}
          descriptionUp={component.primary.description_up.text}
          subtitleDown={component.primary.subtitle_down.text}
          descriptionDown={component.primary.description_down.html}
          image={component.primary.image.url}
        />
      )
    }
    case 'two_column_text': {
      return (
        <TwoColumnText
          title={component.primary.title1.text}
          leftTitle={component.primary.left_column_title.text}
          leftContent={component.primary.left_column_content.html}
          rightTitle={component.primary.right_column_title.text}
          rightContent={component.primary.right_column_content.html}
        />
      )
    }
    case 'videos_row': {
      return (
        <VideosRow
          title={component.primary.title.text}
          items={component.items.map((item) => ({
            title: item.video_title ? item.video_title.text : null,
            description: item.description ? item.description.text : null,
            video: item.video.url,
            poster: item.poster.url,
            poster_mobile: item.poster_mobile.url,
            testimonial: {
              text: item.testimonial.text,
              author_name: item.testimonial_author_name.text,
              author_position: item.testimonial_author_position.text,
              author_picture: item.testimonial_author_picture.url,
            },
          }))}
        />
      )
    }
    case 'video_with_title': {
      return (
        <VideoWithTitle
          title={
            component.primary && component.primary.video_title
              ? component.primary.video_title.text
              : null
          }
          video={component.primary.video.url}
          useBackground={
            component.primary.useBackground || component.primary.usebackground
          }
          poster={component.primary.poster.url}
        />
      )
    }

    default: {
      return null
    }
  }
}

export default RenderComponent
