import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils'
import tw from 'tailwind.macro'
import Button from '../../General/Buttons/Button'
import Paragraph from '../Paragraph'
import SmartLink from '../SmartLink'

const SplashMantelInterior = styled.div`
  ${tw`
    z-10
    relative
    bg-cover
    bg-no-repeat
    overflow-hidden
  `}

  background-position: left center;

  ${screen('xl')} {
    background-position: right center;
  }

  ${(props) =>
    props.mantelBackground &&
    `background-image: url(${props.mantelBackground});`}
`

const ButtonContainer = styled.div`
  ${tw`
  flex
    md:p-0
    w-full
    justify-center
  `}

  button {
    ${tw`
      py-1
      w-full
      sm:w-auto
      flex
      justify-between
      mt-5
    `}
  }
`
const ParagraphContainer = styled.div`
  max-width:900px;
  margin: 0 auto;
`

const SplashMantel = ({ heading, subheading, mantelBackground, cta }) => (
  <div className="relative">
    <SplashMantelInterior mantelBackground={mantelBackground}>
      <div className="max-w-2xl mx-auto">
        <div className=" relative flex flex-col z-20 xl:px-0 px-5 py-6 xl:py-8 text-center justify-center">
          <h2 className="font-sans font-extrabold text-white text-39px lg:text-6xl mb-2">
            {heading}
          </h2>
          <ParagraphContainer>
            <Paragraph>
              <span className="text-white text-center">{subheading}</span>
            </Paragraph>
          </ParagraphContainer>
          {cta ? (
            <SmartLink to={cta ? cta.url : null} className="no-underline">
              <ButtonContainer>
                <Button
                >
                  {cta ? cta.text : null}
                </Button>
              </ButtonContainer>
            </SmartLink>
          ) : null}
        </div>
      </div>
    </SplashMantelInterior>
  </div>
)

export default SplashMantel
