import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { screen } from '../../../utils'

// components

const Separator = styled.div`
  display: none;
  width: 1px;
  padding: 10px 0;
  margin: 0 1px;
  background-color: #9f9f9f;
  background-clip: content-box;

  ${screen('md')} {
    display: block;
  }
`

const TabNav = ({ items, useSmallMargins }) => {
  const containerClasses = `
    mt-4 md:${useSmallMargins ? 'mt-3' : 'mt-7'}
    mb-8 md:${useSmallMargins ? 'mb-3' : 'mb-10'}
    p-1px
    mx-auto
    bg-white
    max-w-3xl
    flex flex-col md:flex-row
    w-full
    border
    border-grey
    rounded
    shadow-blur
    text-charcoal
  `

  const aClasses = `
    font-black
    no-underline
    p-3
    text-center
    flex
    items-center
    justify-center
    flex-1
    relative
    rounded-sm
  `

  return (
    <div className={containerClasses}>
      {items &&
        items.map((item, index) => {
          const className = classNames(
            aClasses,
            'text-charcoal',
            { 'border-b border-grey md:border-0': index < items.length - 1 },
            'hover:text-charcoal hover:bg-light-grey-1'
          )

          if (item.url) {
            return (
              <>
                <Link
                  className={className}
                  key={'navItem' + index}
                  to={item.url}
                  disabled={item.active}
                  activeClassName="text-white bg-black pointer-events-none"
                  activeStyle={{ color: 'white' }}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></Link>
                {index < items.length - 1 && <Separator />}
              </>
            )
          } else {
            return (
              <>
                <a
                  className={className}
                  key={'navItem' + index}
                  href={`#${item.anchor}`}
                  disabled={item.active}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></a>
                {index < items.length - 1 && <Separator />}
              </>
            )
          }
        })}
    </div>
  )
}

export default TabNav
