import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import classnames from 'classnames'

const AllContainer = styled.div``
const Inner = styled.div`
  ${tw`
   flex-col
   flex
   md:flex-row
   my-0
   mx-auto
   lg:pt-6
   pt-0
  `}
`

const TextContainer = styled.div`
  ${tw`
    pt-5
    md:pt-0
    pb-8
    mx-0
    md:px-0
    md:pb-8
    w-full
  `}
`

const ContainerDescriptionDown = styled.div`
  ${tw`
    text-base
    font-light
    leading-relaxed
    border'
  `}

  ul {
    padding-left: 18px;
  }
`

const ContainerContent = styled.div`
  ${tw`
    text-charcoal
    font-sans 
    max-w-2xl
    w-full
    mx-auto
    my-0
  `}
`

function TwoColumnText({
  title,
  leftTitle,
  leftContent,
  rightTitle,
  rightContent,
}) {
  return (
    <AllContainer>
      <Inner>
        <ContainerContent>
          <TextContainer>
            <div
              className={classnames(
                'text-35px',
                'font-extrabold',
                'pb-3',
                'lg:pb-5'
              )}
            >
              {title}
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="pb-3 md:pb-5 lg:pb-6">
                <div className={classnames('text-2xl', 'font-bold', 'pb-3')}>
                  {leftTitle}
                </div>
                <div
                  className={classnames(
                    'text-base',
                    'lg:max-w-lg',
                    'font-light',
                    'leading-relaxed'
                  )}
                  dangerouslySetInnerHTML={{ __html: leftContent }}
                />
              </div>
              <div className="font-sans">
                <div className={classnames('text-2xl', 'font-bold', 'pb-3')}>
                  {rightTitle}
                </div>
                <ContainerDescriptionDown
                  dangerouslySetInnerHTML={{ __html: rightContent }}
                />
              </div>
            </div>
          </TextContainer>
        </ContainerContent>
      </Inner>
    </AllContainer>
  )
}

export default TwoColumnText
