import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import classnames from 'classnames'
import quoteImage from '../../../../../images/white-quote.png'

const SlideContainer = styled.div`
  display: flex;
  padding: 0;
  width: 100%;
  max-width: 1000px;
`

const Image = styled.img`
  ${tw`
  absolute 
  object-top 
  -zIndez-1 
  w-full 
  h-full 
  align-center 
  filter 
  blur-lg 
  md:filter-none
  h-full
  `}
  object-fit: cover;
`

function QuotesSliderSlide({ image, quote, author, position }) {
  return (
    <div>
      <SlideContainer>
        <div
          className={classnames(
            'relative',
            'text-white',
            'font-sans',
            'leading-none',
            'sm:leading-normal',
            'lg:leading-normal',
            'xl:leading-relaxed',
            'pb-2',
            'filter',
            'drop-shadow-md',
            'md:drop-shadow-none',
            'w-full'
          )}
        >
          <Image src={image} />
          <div
            className={classnames(
              'relative',
              'flex',
              'flex-col',
              'w-3/5',
              'md:w-2/5',
              'pl-2',
              'sm:pl-3',
              'lg:pl-4',
              'xl:pl-6',
              'pt-2',
              'sm:pt-4',
              'md:pt-4',
              'xl:pt-5'
            )}
          >
            <img
              className={classnames(
                'w-16',
                'zIndez-10',
                'pb-2',
                'xl:pb-4',
                'ml-2',
                'xl:ml-0'
              )}
              src={quoteImage}
            />
            <div
              className={classnames(
                'sm:pb-0',
                'lg:pb-2',
                'xl:pb-4',
                'text-2xl',
                'leading-normal',
                'md:leading-relaxed',
                'filter',
                'drop-shadow-md',
                'md:drop-shadow-none',
                'ml-2',
                'xl:ml-0'
              )}
            >
              {quote}
            </div>
            <div
              className={classnames(
                'pt-1',
                'xl:pt-0',
                'ml-2',
                'xl:ml-0',
                'flex',
                'flex-col',
                'lg:leading-normal',
                'xl:leading-relaxed',
                'text-base',
                'lg:text-lg',
                'xl:text-xl'
              )}
            >
              <div
                className={classnames(
                  'py-1',
                  'xl:pb-2',
                  'lg:pt-0',
                  'text-left',
                  'lg:text-right',
                  'leading-none'
                )}
              >
                {author}
              </div>
              <div
                className={classnames(
                  'italic',
                  'font-hairline',
                  'text-left',
                  'lg:text-right',
                  'pb-4',
                  'lg:pb-7',
                  'leading-none',
                  'pt-1',
                  'lg:pt-0'
                )}
              >
                {position}
              </div>
            </div>
          </div>
        </div>
      </SlideContainer>
    </div>
  )
}

export default QuotesSliderSlide
