import React from 'react'
import styled from 'styled-components'
// assets
import tw from 'tailwind.macro'
import classNames from 'classnames'

const Inner = styled.div`
  ${tw`
    px-3
    xl:px-0
    py-6
    md:pb-10
    md:pt-8
  `}

  max-width: 1100px;
  margin: 0 auto;
`
const ContainerQuote = styled.div`
  ${tw`
    -mx-6
    xl:mx-0
  `}
`
const SkewImageContainer = styled.div`
  transform: skew(-20deg);
  overflow: hidden;

  img {
    transform: skew(20deg);
    max-width: none;
    object-fit: cover;
    margin-left: -55px;
    margin-right: -55px;
    margin-bottom: -30px;
    width: calc(100% + 120px);
    height: 200px;
  }
  ${tw`
    mx-6
    md:mx-8
    mb-3
    md:pb-0
    md:w-1/3 
    md:mr-5 
  `}
`

const ImageContainer = styled.div`
  overflow: hidden;

  img {
    max-width: none;
    object-fit: cover;
    width: 100%;
  }
  ${tw`
    mx-6
    md:mx-8
    mb-3
    md:pb-0
    md:w-1/3 
    md:mr-5 
    mb-5
    md:mb-0
  `}
`

const BlockquoteImage = ({
  image,
  quote,
  author,
  position,
  className,
  skew,
}) => (
  <ContainerQuote>
    <div className={`text-charcoal ${className || ''}`}>
      <Inner>
        <div className=" max-w-4xl mx-auto ">
          <div className="md:flex">
            {skew === false ? (
              <ImageContainer>
                {image && image.src && (
                  <img src={image.src} className="w-full" />
                )}
              </ImageContainer>
            ) : (
              <SkewImageContainer>
                {image && image.src && (
                  <img src={image.src} className="w-full" />
                )}
              </SkewImageContainer>
            )}
            <div className="md:w-2/3 px-0 md:pr-6 lg:pl-6 md:pb-3 flex items-end">
              <figure>
                <blockquote>
                  <p
                    className={classNames(
                      'text-lg',
                      'md:text-2xl',
                      'font-light',
                      'font-sans',
                      'leading-normal'
                    )}
                  >
                    {quote}
                  </p>
                </blockquote>
                {author && (
                  <figcaption
                    className={classNames(
                      'text-sm',
                      'md:text-base',
                      'leading-tight',
                      'md:w-2/3',
                      'md:text-right',
                      'md:ml-auto',
                      'md:pt-2',
                      'pt-3'
                    )}
                  >
                    - {author}
                    {position ? (
                      <span
                        className="italic"
                        dangerouslySetInnerHTML={{
                          __html: `, <em>${position}</em>`,
                        }}
                      ></span>
                    ) : null}
                  </figcaption>
                )}
              </figure>
            </div>
          </div>
        </div>
      </Inner>
    </div>
  </ContainerQuote>
)

export default BlockquoteImage
