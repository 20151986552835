import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { screen } from '../../../../../utils/index'
import tw from 'tailwind.macro'
import pageBg from '../../../../../images/pageBgs/v2/our-experience-background.jpg'

import SmartLink from '../../../../../components/General/SmartLink'
import GradientButton from '../../../../../components/General/Buttons/GradientButton'

const Inner = styled.div`
  ${tw`
  max-w-2xl
 
`}
  margin:-0 auto
`

const Container = styled.div`
  background: url(${pageBg}) top left no-repeat;
  background-size: cover;
  ${screen('lg')} {
    background-size: 106% 106%;
  }
  ${tw`
  pt-6
  -mx-6
  xl:mx-0
  px-3
  xl:px-0
`}
`

const ContainerDescription = styled.div`
  ${tw`
    font-sans
    leading-relaxed
    text-charcoal
    font-thin
  `}
`

function ImageWithTwoColumns({ title, image, description, cta }) {
  return (
    <Container>
      <Inner>
        <h3
          className={classnames(
            'text-35px',
            'leading-none',
            'font-sans',
            'font-extrabold',
            'mb-3',
            'mt-0',
            'uppercase',

          )}
        >
          {title}
        </h3>
        <div>
          <img className="w-full" src={image} />
        </div>
        <div className="lg:flex row">
          <div className="w-full lg:w-1/2 pt-3 pb-3 lg:pb-6">
            <ContainerDescription
              dangerouslySetInnerHTML={{ __html: description }}
            ></ContainerDescription>
          </div>
          <div
            className={classnames(
              'w-full',
              'md:w-1/2',
              'lg:m-auto',
              'md:pl-2',
              'md-flex',
              'flex-col',
              'pl-0',
              'mb-4'
            )}
          >
            <SmartLink to={cta ? cta.url : null} className="no-underline">
              <GradientButton>{cta ? cta.text : null}</GradientButton>
            </SmartLink>
          </div>
        </div>
      </Inner>
    </Container>
  )
}

export default ImageWithTwoColumns
