import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import { screen } from '../../../../../utils/index'
import classnames from 'classnames'
import Button from '../../../../../components/General/Buttons/Button'
import SmartLink from '../../../../../components/General/SmartLink'

function TwoColumnWithItems({
  title,
  columnLeftTitle,
  columnLeftDescription,
  columnRightTitle,
  columnRightDescription,
  cta,
  items,
  itemsTitle,
}) {
  const ContainerTwoColumn = styled.div`
    background: #5aaf39;

    ${tw`
    -mx-6
    xl:-mx-0
    
      
    `}

    overflow: 0;
    overflow: hidden;
    position: relative;

    ${screen('xl')} {
      padding-left: 0;
      padding-right: 0;
    }
  `

  const ColumnRight = styled.div`
    ${tw`
      font-thin
      leading-relaxed
      text-base
      lg:text-17-1/2
      my-1
      
      `}
    ul {
      padding-left: 18px;
    }
  `

  const Inner = styled.div`
    margin: 0 auto;
    ${tw`
      max-w-2xl
      px-3
      md:px-4
      xl:px-0
      text-charcoal
    
    `}
  `
  const ButtonContainer = styled.div`
    ${tw`
    md:p-0
    w-full
    md:w-auto
    lg:w-auto
    inline-flex
  `}

    button {
      ${tw`
      
      w-full
      flex
      justify-between
      hover:bg-white-trans
      lg:mb-6
      lg:mt-4
      my-5
    `}
    }
  `

  return (
    <ContainerTwoColumn>
      <Inner>
        <div className="relative">
          <div className="relative pt-8 pb-3 ">
            <div
              className={classnames(
                'leading-none',
                'text-35px',
                'font-extrabold',
                'font-sans',
                'uppercase'
              )}
            >
              {title}
            </div>
            <div
              className={classnames('flex', 'flex-col', 'md:flex-row', 'py-4')}
            >
              <div className="w-full md:w-1/2">
                <div
                  className={classnames(
                    'font-bold',
                    'leading-zero',
                    'text-2xl',
                    'pb-3',
                    'pt-2'
                  )}
                >
                  {columnLeftTitle}
                </div>
                <div
                  className={classnames(
                    'font-thin',
                    'leading-relaxed',
                    'text-base',
                    'lg:text-17-1/2',
                    'mr-2',
                    'my-1'
                  )}
                >
                  {columnLeftDescription}
                </div>
              </div>
              <h3 className="w-full md:w-1/2">
                <div
                  className={classnames(
                    'font-bold',
                    'leading-zero',
                    'text-2xl',
                    'pb-3',
                    'pt-2',
                    'pl-0'
                  )}
                >
                  {columnRightTitle}
                </div>
                <ColumnRight
                  dangerouslySetInnerHTML={{ __html: columnRightDescription }}
                />
              </h3>
            </div>
          </div>
          <div>
            <h3 className="pb-3 text-2xl font-bold">{itemsTitle}</h3>
            <div
              className={classnames(
                'flex',
                'flex-col',
                'md:flex-row',
                'items-center',
                'justify-between'
              )}
            >
              {items
                ? items.map((item, index) => {
                    return (
                      <div
                        className={classnames(
                          'w-full',
                          'md:w-1/4',
                          'flex',
                          'flex-col',
                          'mb-1',
                          'md:mb-0',
                          'text-center',
                          'items-center',
                          'mr-0',
                          'md:mr-1',
                          'bg-white',
                          'shadow-lg',
                          { 'md:ml-0': index === 0 },
                          { 'md:mr-0': index === items.length - 1 }
                        )}
                        key={`item_${index}`}
                      >
                        <img className=" h-20 mt-5" src={item.icon} />
                        <strong className="text-charcoal pb-5 pt-0">
                          {item.text}
                        </strong>
                      </div>
                    )
                  })
                : null}
            </div>
            <SmartLink to={cta ? cta.url : null} className="no-underline">
              <ButtonContainer>
                <Button
                  transparent
                  borderedWhite
                  className="border border-1 border-white"
                >
                  {cta ? cta.text : null}
                </Button>
              </ButtonContainer>
            </SmartLink>
          </div>
        </div>
      </Inner>
    </ContainerTwoColumn>
  )
}

export default TwoColumnWithItems
