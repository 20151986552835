import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import QuotesSliderSlide from './QuotesSliderSlide'
import sliderArrow from '../../../../../images/slider-arrow.png'
import tw from 'tailwind.macro'

import 'swiper/swiper.min.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

const defaultParams = {
  centeredSlides: true,
  navigation: null,
  spaceBetween: 100,
  loop: true,
  autoplay: {
    delay: 4000
  },
  slidesPerView: 1,
  pagination: {
    el: '.swiper-dots',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: '.arrow-next',
    prevEl: '.arrow-prev',
  },
}

const Inner = styled.div`
  overflow: hidden;
  display: flex;
  ${tw`
  max-w-2xl
`}
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 25px;

  .swiper-slide {
    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }
`

const ArrowLeft = styled.div`
  ${tw`
    mr-3
    cursor-pointer
    hidden
    md:flex
    items-center
    justify-end
    opacity-50
    `}

  &.swiper-button-disabled {
    ${tw`
        opacity-25
      `}
  }

  height: 100px;
  width: 100px;
  img {
    height: 37px;
  }
`

const ArrowRight = styled.div`
  ${tw`
  ml-3
  cursor-pointer
  hidden
  md:flex
  items-center
  justify-start
  opacity-50
  `}

  &.swiper-button-disabled {
    ${tw`
      opacity-25
    `}
  }

  height: 100px;
  width: 100px;
  img {
    height: 37px;
    transform: rotate(180deg);
  }
`

const SwiperDots = styled.div`
  ${tw`
    flex
    mt-2
    justify-center
    flex
    md:hidden
  `}

  .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #d1506a;
    opacity: 0.5;
    margin: 0 5px;

    &.swiper-pagination-bullet-active {
      background: #b72375;
      opacity: 1;
    }
  }
`

function QuotesSlider({ items, customParams }) {
  const instanceParams = {
    ...defaultParams,
    ...customParams,
  }

  return items && items.length ? (
    <>
      <Container>
        <ArrowLeft className="arrow-prev">
          <img src={sliderArrow} />
        </ArrowLeft>
        <Inner>
          <Swiper {...instanceParams}>
            {items.map((slide, index) => (
              <SwiperSlide>
                <QuotesSliderSlide key={index} {...slide} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Inner>
        <ArrowRight className="arrow-next">
          <img src={sliderArrow} />
        </ArrowRight>
      </Container>
      <SwiperDots className="swiper-dots" />
    </>
  ) : null
}

export default QuotesSlider
